.tiny-title {
  @apply uppercase tracking-[2px] text-xs xl:text-sm;
}

.transition-element {
  @apply transition duration-300 ease-in-out;
}

.transition-element2 {
  @apply transition duration-700 ease-in-out;
}

@keyframes rotateAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rotating-element {
  animation: rotateAnimation 4s linear infinite;
}

.orange-carousel-gradient {
  background: linear-gradient(180deg, rgba(255, 158, 16, 0) 0%, #ff9e10 42.71%);
}

.green-carousel-gradient {
  background: linear-gradient(180deg, rgba(39, 224, 163, 0) 0%, #27e0a3 42.71%);
}

.marquee-parent > div > div {
  display: flex !important;
}
