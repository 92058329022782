/** @format */

@font-face {
  font-family: "Gramatika-Bold";
  src: url(../assets/fonts/Gramatika-Bold.ttf) format("truetype");
  font-display: fallback;
}

@font-face {
  font-family: "Gramatika-Medium";
  src: url(../assets/fonts/Gramatika-Medium.ttf) format("truetype");
  font-display: fallback;
}

@font-face {
  font-family: "Gramatika-Regular";
  src: url(../assets/fonts/Gramatika-Regular.ttf) format("truetype");
  font-display: fallback;
}

@font-face {
  font-family: "Gramatika-Light";
  src: url(../assets/fonts/Gramatika-Light.ttf) format("truetype");
  font-display: fallback;
}

@font-face {
  font-family: "SF-Pro-Text-Bold";
  src: url(../assets/fonts/SF-Pro-Text-Bold.otf) format("truetype");
  font-display: fallback;
}

@font-face {
  font-family: "SF-Pro-Text-Regular";
  src: url(../assets/fonts/SF-Pro-Text-Regular.otf) format("truetype");
  font-display: fallback;
}

* {
  font-family: Gramatika-Regular, sans-serif !important;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: subpixel-antialiased !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  font-synthesis: none !important;
}

.light {
  font-family: Gramatika-Light, sans-serif !important;
}

.medium {
  font-family: Gramatika-Medium, sans-serif !important;
}

.bold {
  font-family: Gramatika-Bold, sans-serif !important;
}

.sf {
  font-family: SF-Pro-Text-Regular, sans-serif !important;
}

.sf-bold {
  font-family: SF-Pro-Text-Bold, sans-serif !important;
}
